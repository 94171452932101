import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'recurrence', 'reservation_id', 'presence' ]

  connect() {
  }

  recurrence_change(){

    const recurrence_type = this.recurrenceTarget.value

    if (recurrence_type === 'personnaliser')
    {
      $('#recurrenceModal').modal()
    }
  }

  recurrence_day(){

    const recurrence_type = this.recurrenceTarget.value

    if (recurrence_type === 'personnaliser')
    {
      $('#recurrenceModal').modal()
    }
  }

  validate_reservation() {
    let element = this.reservation_idTarget
    const reservation_id = element.value

    element = this.presenceTarget
    const presence = element.value

    $.ajax({
      method: 'PATCH',
      url: `/event_reservations/${reservation_id}`,
      data: {'event_reservation[presence]': presence },
      success: (data) => {
        const id = `icon_${reservation_id}`
        let icon = document.getElementById(id)

        icon.classList.toggle('text-success');
        icon.classList.toggle('text-danger');
        icon.classList.toggle('fa-thumbs-down');
        icon.classList.toggle('fa-thumbs-up');
      },
      error: () => {
        // TODO - remonter le message
        alert('Erreur inconnu 006, veuillez contacter le support.')
      },
      dataType: 'script'
    });

  }
}