import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "group_id",
    "status_adhesion", "status_adhesion_user", "pending_adhesion_amount", "validate_adhesions_form",
    "status_reservation", "status_reservation_user", "pending_reservation_amount", "validate_reservations_form",
    "member_status", "new_group"]

  validate_adhesions() {

    const status =  this.status_adhesionTarget.value

    if (status === '0')
    {
      alert("Veuillez sélectionner un moyen de paiement.")
      return
    }

    const group_id = this.group_idTarget.value

    $.ajax({
      method: 'PATCH',
      url: `/groups/${group_id}/validate_adhesions`,
      data: {status: status },
      success: (data) => {

        document.location.reload();

      },
      error: () => {
        // TODO - remonter le message
        alert('Erreur inconnu 001, veuillez contacter le support.')
      },
      dataType: 'script'
    });


  }

  migrate_members() {
    const new_group =  this.new_groupTarget.value
    const group_id = this.group_idTarget.value

    $.ajax({
      method: 'PATCH',
      url: `/groups/${group_id}/migrate_members`,
      data: {new_group: new_group },
      success: (data) => {
        alert('Migration effectué')
        document.location.reload()
      },
      error: () => {
        alert('Erreur inconnu 005, veuillez contacter le support.')
      },
      dataType: 'script'
    });

  }

  validate_reservations() {

    const status =  this.status_reservationTarget.value

    if (status === 0)
    {
      alert("Veuillez sélectionner un moyen de paiement.")
      return
    }

    const group_id = this.group_idTarget.value

    $.ajax({
      method: 'PATCH',
      url: `/groups/${group_id}/validate_reservations`,
      data: {status: status },
      success: (data) => {

        this.pending_reservation_amountTarget.innerText = '0'


        this.validate_reservations_formTarget.style.display = 'none';


        this.status_reservation_userTargets.forEach((el) => {

          const val = el.innerText

          if(val === 'unpaid') {

            switch (status) {
              case '1':
                el.innerHTML = "Payé par carte"
                break;
              case '2':
                el.innerHTML = "Payé en chèque"
                break;
              case '3':
                el.innerHTML = "Payé en espèce"
                break;
            }
          }

        })

      },
      error: () => {
        // TODO - remonter le message
        alert('Erreur inconnu 002, veuillez contacter le support.')
      },
      dataType: 'script'
    });
  }

  set_admin()
  {
    const element = event.target
    // console.log(element)
    const el_row = document.querySelector(element.dataset.groupRow)
    const el_label = document.querySelector(element.dataset.groupLabel)
    const group_member_id = element.dataset.groupId

    $.ajax({
      method: 'PATCH',
      url: `/group_members/${group_member_id}/set_admin`,
      success: (data) => {

        const val = el_label.innerText

        if(val === 'administrateur') {
          el_label.innerText = 'observateur'
        } else if (val === 'membre') {
          el_label.innerText = 'administrateur'
        } else {
          el_label.innerText = 'membre'
        }

        el_row.classList.toggle("table-success");

      },
      error: () => {
        // TODO - remonter le message
        alert('Erreur inconnu 003, veuillez contacter le support.')
      },
      dataType: 'script'
    });



    // alert('admin -- ' + el + ' -- ' + id )
  }
}