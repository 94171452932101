
import { Controller } from "stimulus"
import { Calendar } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import frLocale from '@fullcalendar/core/locales/fr';
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = [ "calendar", "modal", "start_time", "end_time", "recurrence", "resource" ]

  connect() {
    let _this = this

    let calendar = new Calendar(this.calendarTarget, {
      events: '/api/v1/resource_sessions.json?resource_id=' + this.resourceTarget.value,
      editable: true,
      navLinks: true,
      locale: frLocale,
      headerToolbar: { center: 'dayGridMonth,timeGridWeek,timeGridDay' },
      plugins: [dayGridPlugin,timeGridPlugin,interactionPlugin],

      navLinkDayClick: function (date, jsEvent) {
        _this.start_timeTarget.value = date
        _this.end_timeTarget.value = date
        $('#calendar_modal').modal('show')
      },

      eventClick: function (info) {
        info.jsEvent.preventDefault()
        // Turbo.visit(info.event.extendedProps.show_url)
      },

      eventDrop: function (info) {
        let data = _this.data(info)
        Rails.ajax({
          type: 'PUT',
          url: info.event.url,
          data: new URLSearchParams(data).toString()
        })
      },

      eventResize: function (info) {
        let data = _this.data(info)
        Rails.ajax({
          type: 'PUT',
          url: info.event.url,
          data: new URLSearchParams(data).toString()
        })
      },
    })

    calendar.render()
  }

  data(info) {
    return {
      "resource_session[start_time]": info.event.start,
      "resource_session[end_time]": info.event.end,
    }
  }

  recurrence_change(){

    const recurrence_type = this.recurrenceTarget.value

    if (recurrence_type === 'personnaliser')
    {
      $('#recurence_custom_days').removeClass('d-none')
    } else {
      $('#recurence_custom_days').addClass('d-none')
    }
  }

}
