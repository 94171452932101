import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "event_id", "price", "vat" ]

  set_amount(event) {

    const e = event.target
    var event_id = e.options[e.selectedIndex].value;

    $.get({
      url: `/events/${event_id}.json`,
      success: (data) => {

        let event = JSON.parse(data)
        this.priceTarget.value = event.event_price
        this.vatTarget.value = event.vat
      },
      dataType: 'script'
    });

  }
}
