import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "name", "output" ]

  connect() {
    this.get_age()
  }

  get_age(){
    const now = new Date()

    const el_day   = document.getElementById('customer_birthday_3i')
    const el_month = document.getElementById('customer_birthday_2i')
    const el_year  = document.getElementById('customer_birthday_1i')

    if ( !el_day  || !el_month  || !el_year) {
      return
    }

    const day   = el_day.options[el_day.selectedIndex].value;
    const month = el_month.options[el_month.selectedIndex].value;
    const year  = el_year.options[el_year.selectedIndex].value;

    if ( !day  || !month  || !year) {
      return
    }

    const birthday = new Date(year, month, day)
    const age = now - birthday
    const human_age = Math.floor(age / (1000 * 60 * 60 * 24 * 365.25))
                                     //  millisecondes -> secondes -> minutes -> heures -> jour -> année

    const age_el = document.getElementById('age-text')
    age_el.innerText = 'Soit ' + human_age + ' ans'

    if (human_age >= 18) {
      document.getElementById('adult-features-nav').style.display = "block";
      document.getElementById('child-features-nav').style.display = "none";
    }
    else {
      // document.getElementById('adult-features-nav').style.display = "none";
      document.getElementById('child-features-nav').style.display = "block";

    }
  }
}