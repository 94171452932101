import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "name", "output" ]


  activate(event) {
    const element = event.target
    var el = document.querySelector(element.dataset.appEl);
    el.removeAttribute("readonly");
  }

  show(event) {
    const element = event.target
    var el = document.querySelector(element.dataset.appEl);
    el.classList.toggle("d-none");
  }


}