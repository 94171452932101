import { Controller } from "stimulus"
import {start} from "@rails/activestorage/src";

export default class extends Controller {
  static targets = [ "adhesion_id", "amount", "vat" ]

  set_amount(event) {

    const e = event.target
    var adhesion_id = e.options[e.selectedIndex].value;

    $.get({
      url: `/adhesions/${adhesion_id}.json`,
      success: (data) => {
        let adhesion = JSON.parse(data);
        this.amountTarget.value = adhesion.amount
        this.vatTarget.value = adhesion.vat
      },
      dataType: 'script'
    });

  }
}