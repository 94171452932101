import { Controller } from "stimulus"

export default class extends Controller {
  static targets = []

  toggle_password(e) {

    const element = e.target
    const password_field = document.getElementById(element.dataset.id)
    const type = password_field.type

    if(type === 'password') {
      password_field.type = 'text';
    } else {
      password_field.type = 'password';
    }
  }
}