
import { Controller } from "stimulus"
import { Calendar } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import frLocale from '@fullcalendar/core/locales/fr';

export default class extends Controller {
  static targets = ["calendar", "appointment", "title", 'btn']

  connect() {
    let _this = this

    _this.setCalendar()
    _this.calendarTarget.hidden = true
  }

  setCalendar() {
    const _this = this
    let calendar = new Calendar(this.calendarTarget, {
      timeZone: 'Europe/Paris',
      events: '/api/v1/pre_registration_appointments.json',
      editable: true,
      navLinks: true,
      locale: frLocale,
      height: 600,
      initialDate: new Date('2021-09-07T15:00:00'),
      initialView: 'dayGridWeek',
      headerToolbar: { center: 'dayGridWeek' },
      plugins: [dayGridPlugin,timeGridPlugin,interactionPlugin],


      eventClick: function (info) {
        info.jsEvent.preventDefault()
        const event = info.event
        _this.appointmentTarget.value = event.id
        _this.calendarTarget.hidden = true
        _this.titleTarget.innerHTML = "Rendez-vous selectionné : " + event.extendedProps.start_date
        _this.btnTarget.innerHTML = "Changer le rendez-vous"
      },

    })

    calendar.render()

  }

  chooseAppointment() {
    this.calendarTarget.hidden = false
    this.setCalendar()
  }

  changeAppointment() {
    this.calendarTarget.hidden = false
    this.setCalendar()
  }

}
